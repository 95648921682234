import helper from '@/utils/helper';
import constants from '@/utils/constant';

import { usePermissionsRolesStore } from '@/store/permissionsRoles';

const checkAuth = ({ next }: { next: () => void | null }): void | null => {
  const permissionsRolesStore = usePermissionsRolesStore();
  const utcTimestamp: number = new Date().getTime();

  if (
    permissionsRolesStore.token &&
    permissionsRolesStore.expires_at &&
    utcTimestamp <= new Date(permissionsRolesStore.expires_at).getTime()
  ) {
    return next();
  }
  permissionsRolesStore.$reset();
  helper.redirectToWp(constants.RESOURCE_LOGIN_WP_ERR_403);
  return null;
};

export default checkAuth;
