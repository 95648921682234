import Swal from 'sweetalert2';

const alertModal = Swal.mixin({
  confirmButtonText: 'はい',
  customClass: {
    confirmButton: 'btn btn-swal'
  },
  buttonsStyling: false
});

const confirmModal = Swal.mixin({
  showCancelButton: true,
  confirmButtonText: 'はい',
  cancelButtonText: 'いいえ',
  customClass: {
    confirmButton: 'btn btn-swal',
    cancelButton: 'btn btn-swal',
    popup: 'w-auto text-nowrap'
  },
  buttonsStyling: false
});

export { alertModal, confirmModal };
