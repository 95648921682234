import { defineStore } from 'pinia';

interface PermissionsRolesState {
  role: string;
  token: string;
  id: number;
  fullname: string;
  email: string;
  expires_at: string;
}
export const usePermissionsRolesStore = defineStore('permissionsRoles', {
  state: (): PermissionsRolesState => {
    return {
      role: '',
      token: '',
      id: null,
      fullname: '',
      email: '',
      expires_at: ''
    };
  },
  actions: {
    saveRoles(roles: string): void {
      this.role = roles;
    },
    saveToken(token: string): void {
      this.token = token;
    },
    saveId(id: number): void {
      this.id = id;
    },
    saveFullname(fullname: string): void {
      this.fullname = fullname;
    },
    saveEmail(email: string): void {
      this.email = email;
    },
    saveExpiresAt(expires_at: string): void {
      this.expires_at = expires_at;
    }
  }
});
