import { rolesConfig } from '@/json/roles_cofig';

import { usePermissionsRolesStore } from '@/store/permissionsRoles';

export default ({ next, to }: { next: Function; to: any }) => {
  const screen = to.hash ? to.name + '_' + to.hash.replace(/#/g, '') : to.name;
  const permissionsRolesStore = usePermissionsRolesStore();

  if (['notfound', 'invite.confirm', 'token.has.expired'].includes(screen)) {
    return next();
  }

  for (const role of rolesConfig) {
    if (role.name === screen.replace(/\./g, '_')) {
      if (Object.keys(role.roles).includes(permissionsRolesStore.role)) {
        return next();
      }
    }
  }

  permissionsRolesStore.$reset();
  window.location.href = '/home';
  return false;
};
