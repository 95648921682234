<template>
  <div class="d-flex min-vh-100 flex-column">
    <main id="main-simple">
      <slot></slot>
    </main>
  </div>
</template>

<style>
#main-simple {
  background: #a8a8a8;
  flex-grow: 1;
}
</style>
