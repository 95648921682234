<template>
  <svg
    class="icon-top-nav"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      d="M8,7.375,0,13.261V23.375H6.3v-5.4H9.7v5.4H16V13.261Z"
      transform="translate(0 -7.375)"
    />
  </svg>
</template>
