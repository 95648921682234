<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g
      id="ico-newsletter"
      transform="translate(0 -31.506)"
    >
      <path
        id="Path_1068"
        data-name="Path 1068"
        d="M93.539,128.378H99.21l2.49-2.84.023-.027v-.467H93.539Z"
        transform="translate(-90.616 -90.206)"
      />
      <path
        id="Path_1069"
        data-name="Path 1069"
        d="M12.569,45.839H1.462V33.173H12.569v.47L14.031,32.4v-.893H0v16H14.031V40.689l-1.462,1.667Z"
      />
      <path
        id="Path_1070"
        data-name="Path 1070"
        d="M97.783,274.708H93.539v1H97.3A9.363,9.363,0,0,1,97.783,274.708Z"
        transform="translate(-90.616 -234.535)"
      />
      <path
        id="Path_1071"
        data-name="Path 1071"
        d="M93.539,349.539v1h3.467a2.709,2.709,0,0,1-.114-1Z"
        transform="translate(-90.616 -306.7)"
      />
      <path
        id="Path_1072"
        data-name="Path 1072"
        d="M248.692,207.3c-.484.552-1.738,2.878-1.253,3.431s2.524-.877,3.008-1.429l1.992-2.272-1.755-2Z"
        transform="translate(-239.6 -167.339)"
      />
      <path
        id="Path_1073"
        data-name="Path 1073"
        d="M371.693,104.3a1.136,1.136,0,0,0-1.755,0l-2.4,2.733,1.755,2,2.4-2.733A1.559,1.559,0,0,0,371.693,104.3Zm-3.132,3.1-.3-.343,1.678-1.913.3.343Z"
        transform="translate(-356.056 -69.797)"
      />
    </g>
  </svg>
</template>
