/**
 * resource name of endpoint api
 */
const API_USER_RESOURCE: string = 'users';
const API_ARTICLE_RESOURCE: string = 'articles';
const API_FOLDER_RESOURCE: string = 'folders';
const API_NEWSLETTER_RESOURCE: string = 'newsletters';
const API_ISSUE_RESOURCE: string = 'issues';
const API_PRODUCT_RESOURCE: string = 'products';
const API_TASK_RESOURCE: string = 'tasks';
const API_ZIPCODE_RESOURCE: string = 'zipcodes';
const API_QUEUE_RESOURCE: string = 'queues';

/**
 * wordpress error page url
 */
const RESOURCE_LOGIN_WP_ERR_403: string = 'login?error=403';
const RESOURCE_LOGIN_WP_ERR_500: string = 'login?error=500';

/**
 * default page title
 */
const DEFAULT_TITLE: string = 'ダッシュボード';

/**
 * timeout for all request
 */
const TIME_OUT_AXIOS: number = 15000;

/**
 * article status list
 */
const ALL_STATUSES: { id: string; title: string }[] = [
  {
    id: 'draft',
    title: '下書き'
  },
  {
    id: 'reserved',
    title: '予約済み'
  },
  {
    id: 'published',
    title: '公開済み'
  }
];

/**
 * Issue status list
 */
const ISSUE_STATUS_LIST = [
  {
    id: 'draft',
    title: '下書き'
  },
  {
    id: 'reserved',
    title: '予約済み'
  },
  {
    id: 'published',
    title: '配信済み'
  }
];

/**
 * newsletter status list
 */
const PUBLISH_DRAFT_STATUSES: { id: string; title: string }[] = [
  {
    id: 'draft',
    title: '下書き'
  },
  {
    id: 'published',
    title: '公開済み'
  }
];

const TYPE_DOWNLOAD = [
  {
    id: 'plaintext',
    title: 'プレーンテキスト'
  },
  {
    id: 'html',
    title: 'HTML'
  }
];

const SOCIAL_NETWORK = [
  {
    id: 'facebook',
    title: 'Facebook'
  }
];

const PERMISSION_FACEBOOK = [
  'pages_manage_engagement',
  'pages_manage_metadata',
  'pages_manage_posts',
  'pages_read_user_content',
  'pages_read_engagement',
  'pages_show_list'
];

const VERSION_GRAPH_API = 'v18.0';

const TIME_OUT_STRIPE = 1000;

const STATUS_TASK = [
  {
    id: 0,
    title: '完了タスクを含む'
  },
  {
    id: 1,
    title: '完了タスクを含まない'
  }
];

const TASK_SITUATION_CREATE = [
  {
    id: 'draft',
    title: '下書き'
  },
  {
    id: 'pending',
    title: '依頼中'
  }
];

const TASK_SITUATION_EDIT = [
  {
    id: 'draft',
    title: '下書き'
  },
  {
    id: 'pending',
    title: '依頼中'
  },
  {
    id: 'in_progress',
    title: '作業中'
  },
  {
    id: 'completed',
    title: '完了'
  }
];

const MEMBER_STATUS = [
  {
    id: 'invited',
    title: '招待中'
  },
  {
    id: 'approved',
    title: '招待済み'
  }
];

const ROLE_NONE = 'role_none';

const ROLES_ORDER = ['publisher', 'editor', 'writer', 'role_none'];

const ROLES_USER = [
  {
    id: 'publisher',
    title: '発行者'
  },
  {
    id: 'editor',
    title: '編集者'
  },
  {
    id: 'writer',
    title: '執筆者'
  }
];

const PAID_FREE = [
  { id: 1, value: false, label: '有料' },
  { id: 2, value: true, label: '無料' }
];

const DISPLAY_ADS_OPTION = [
  { id: 1, value: false, label: '非表示' },
  { id: 2, value: true, label: '表示' }
];

export default {
  API_USER_RESOURCE,
  API_ARTICLE_RESOURCE,
  API_FOLDER_RESOURCE,
  API_ZIPCODE_RESOURCE,
  DEFAULT_TITLE,
  RESOURCE_LOGIN_WP_ERR_403,
  RESOURCE_LOGIN_WP_ERR_500,
  TIME_OUT_AXIOS,
  ALL_STATUSES,
  ISSUE_STATUS_LIST,
  API_NEWSLETTER_RESOURCE,
  API_ISSUE_RESOURCE,
  API_PRODUCT_RESOURCE,
  API_TASK_RESOURCE,
  PUBLISH_DRAFT_STATUSES,
  API_QUEUE_RESOURCE,
  TYPE_DOWNLOAD,
  SOCIAL_NETWORK,
  PERMISSION_FACEBOOK,
  VERSION_GRAPH_API,
  TIME_OUT_STRIPE,
  STATUS_TASK,
  TASK_SITUATION_CREATE,
  TASK_SITUATION_EDIT,
  MEMBER_STATUS,
  ROLES_ORDER,
  ROLE_NONE,
  DISPLAY_ADS_OPTION,
  PAID_FREE,
  ROLES_USER
};
