import { defineStore } from 'pinia';

interface LoadingState {
  isLoading: boolean;
}

export const useLoadingStore = defineStore('loading', {
  state: (): LoadingState => {
    return {
      isLoading: false
    };
  },
  actions: {
    toggleLoading(flag: boolean): void {
      this.isLoading = flag;
    }
  }
});
