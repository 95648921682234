import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized
} from 'vue-router';

import { usePermissionsRolesStore } from '@/store/permissionsRoles';

import authentication from '@/middlewares/authentication';
import authorization from '@/middlewares/authorization';
import middlewarePipeline from '@/middlewares/middlewarePipeline';

import MainLayout from '@/components/layouts/MainLayout.vue';
import SimpleLayout from '@/components/layouts/SimpleLayout.vue';

import constants from '@/utils/constant';
import baseRepository from '@/repositories/baseRepository';
import helper from '@/utils/helper';
import { UserResponseInterface } from '@/types/user';
import { defineAsyncComponent } from 'vue';

const LoginPage = () => import('@/components/pages/LoginPage.vue');
const HomePage = () => import('@/components/pages/HomePage.vue');
const UserDetailPage = () =>
  import('@/components/pages/users/UserDetailPage.vue');
const NewsletterListPage = () =>
  import('@/components/pages/newsletter/list/ListPage.vue');
const NewsletterDetail = () =>
  import('@/components/pages/newsletter/detail/DetailPage.vue');
const NewsletterUserDetail = () =>
  import('@/components/pages/newsletter/user/DetailPage.vue');
const IssueDetail = () =>
  import('@/components/pages/issue/detail/DetailPage.vue');
const TaskDetail = () =>
  import('@/components/pages/task/detail/DetailPage.vue');
const ArticleListPage = () =>
  import('@/components/pages/articles/list/ListPage.vue');
const ArticleDetailPage = () =>
  import('@/components/pages/articles/detail/DetailPage.vue');
const QueueListPage = () => import('@/components/pages/queue/ListPage.vue');
const FolderDetailPage = () =>
  import('@/components/pages/folder/FolderDetailPage.vue');
const FocusedMode = () => import('@/components/pages/articles/FocusedMode.vue');
const NotFound = () => import('@/components/pages/NotFound.vue');
const TokenHasExpired = () => import('@/components/pages/TokenHasExpired.vue');

const UserInvite = () => import('@/components/pages/UserInvite.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { layout: SimpleLayout }
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: NotFound
  },
  {
    path: '/token-has-expired',
    name: 'token.has.expired',
    component: TokenHasExpired
  },
  {
    path: '/home',
    name: 'home',
    alias: '/',
    component: HomePage,
    meta: { title: 'Home' }
  },
  {
    path: '/user',
    name: 'user.detail',
    component: UserDetailPage,
    meta: { title: 'ユーザー情報' },
    props: (route) => {
      return {
        status: String(route.query.status),
        productId: Number(route.query.productId),
        paymentId: Number(route.query.paymentId)
      };
    }
  },
  {
    path: '/invite/confirm',
    name: 'invite.confirm',
    component: defineAsyncComponent({
      loader: UserInvite
    }),
    meta: { title: 'ニューズレター招待の承認' },
    props: (route) => {
      return {
        token: String(route.query.token)
      };
    }
  },
  {
    path: '/newsletter',
    name: 'newsletter.list',
    component: NewsletterListPage,
    meta: { title: 'ニューズレター一覧' }
  },
  {
    path: '/newsletter/create',
    name: 'newsletter.create',
    component: NewsletterDetail,
    meta: { title: 'ニューズレター一覧' }
  },
  {
    path: '/newsletter/:newsletter_id',
    name: 'newsletter.detail',
    component: NewsletterDetail,
    meta: { title: 'ニューズレター一覧' }
  },
  {
    path: '/newsletter/:newsletter_id/users/:id',
    name: 'newsletter.user.detail',
    component: NewsletterUserDetail,
    meta: { title: 'ニューズレター一覧' }
  },
  {
    path: '/newsletter/:newsletter_id/task/create',
    name: 'task.create',
    component: TaskDetail,
    meta: { title: 'タスクの新規作成' }
  },
  {
    path: '/newsletter/:newsletter_id/task/:id',
    name: 'task.detail',
    component: TaskDetail,
    meta: { title: 'タスク詳細' }
  },
  {
    path: '/newsletter/:newsletter_id/issue/create',
    name: 'issue.create',
    component: IssueDetail,
    meta: { title: '号登録' }
  },
  {
    path: '/newsletter/:newsletter_id/issue/:id',
    name: 'issue.detail',
    component: IssueDetail,
    meta: { title: '号詳細' }
  },
  {
    path: '/article',
    name: 'article.list',
    component: ArticleListPage,
    meta: { title: '原稿一覧' }
  },
  {
    path: '/article/create',
    name: 'article.create',
    component: ArticleDetailPage,
    meta: { title: '原稿登録' }
  },
  {
    path: '/article/:id',
    name: 'article.detail',
    component: ArticleDetailPage,
    meta: { title: '原稿詳細' }
  },
  {
    path: '/article/focus/:id?',
    name: 'article.focus',
    component: FocusedMode,
    meta: {
      title: '集中モード',
      layout: SimpleLayout,
      roles: ['editor', 'author']
    }
  },
  {
    path: '/article/:id/simple',
    name: 'article.detail.simple',
    component: ArticleDetailPage,
    meta: { title: '原稿集中モード' }
  },
  {
    path: '/folder/create',
    name: 'folder.create',
    component: FolderDetailPage,
    meta: { title: 'フォルダ登録' }
  },
  {
    path: '/folder/:id',
    name: 'folder.detail',
    component: FolderDetailPage,
    meta: { title: 'フォルダ詳細' }
  },
  {
    path: '/queue',
    name: 'queue.list',
    component: QueueListPage,
    meta: { title: '配信予約一覧' }
  }
];
const router = createRouter({
  history: createWebHistory(),
  routes
});

router.afterEach((to: RouteLocationNormalized) => {
  document.title = <string>to.meta.title || constants.DEFAULT_TITLE;
});

/**
 * get list permission
 *
 * @param {any} params
 */
const getListPermissions = async (params: any) => {
  const permissionsStore = usePermissionsRolesStore();
  const userDataStr = localStorage.getItem('userData');
  const userDataObj = JSON.parse(userDataStr);

  const dataResponse: UserResponseInterface = await baseRepository.get(
    `/users`,
    {
      headers: {
        'Authorization': 'Bearer ' + userDataObj.token
      },
      params: {
        newsletter_id: params.newsletter_id ? Number(params.newsletter_id) : 0
      }
    }
  );
  const userInfo = dataResponse.user;

  const maxRole: string = helper.sortOrderRoles(userInfo.roles);
  permissionsStore.saveRoles(maxRole);
  permissionsStore.saveFullname(userInfo.username);
  permissionsStore.saveId(userInfo.id);
  permissionsStore.saveEmail(userInfo.email);
  permissionsStore.saveToken(dataResponse.auth_key);
  permissionsStore.saveExpiresAt(dataResponse.expires_at);
};

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: Function
  ) => {
    if (!to.meta.layout) {
      to.meta.layout = MainLayout;
    }

    const userDataStr = localStorage.getItem('userData');
    if (
      <string>to.name !== 'login' &&
      (!['notfound', 'invite.confirm', 'token.has.expired'].includes(
        <string>to.name
      ) ||
        userDataStr)
    ) {
      // Call api get permission
      await getListPermissions(to.params);

      const mid = [];

      mid.push(authentication);
      mid.push(authorization);
      to.meta = { ...to.meta, middleware: mid };
    }

    /** Navigate to next if middleware is not applied */
    if (!to.meta.middleware) {
      return next();
    }

    const middleware: Function[] = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [];
    const context = {
      to,
      from,
      next
    };

    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
    });
  }
);

export default router;
