<template>
  <div id="footer">© 2024 THE POWER NEWS / All rights reserved.</div>
</template>

<style scoped lang="scss">
#footer {
  background: #2e2e2e;
  color: #efeeef;
  padding: 20px;
  font-size: 14px;
  height: 58px;
}
</style>
